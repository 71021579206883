import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import Layout from "../../components/layout/main";
import SectionHeading from "../../components/section-heading/main";

export default function NotFound(props) {

    return (
        <div className="not-found">
            <Layout>
                <div className="intro-block">
                    <div className="inner-content">
                        <SectionHeading text={config.intro.heading} />

                        <div className="paragraphs">
                            {config.intro.body.map((paragraph, key) => {
                                return(
                                    <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}
