import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media/index.js';
import { RootContext } from "../../web.js";
import {scrollToElementId} from '../../utilities/general.js';
import Layout from "../../components/layout/main.js";
import ArrowButton from "../../components/arrow-button/main.js";
import SectionHeading from "../../components/section-heading/main.js";
import ContactSection from "../../components/contact-section/main.js";


export default function Contact(props) {

    const root = useContext(RootContext);


    return (
        <div className="contact">
            <Layout pageId={0}>
                <div className='contact-container'>
                    <div className="text-holder">
                        <h2 className="heading">{config.contact.heading}</h2>
                        <h3 className="body">{config.contact.body}</h3>
                    </div>
                    <ContactSection />
                </div>

            </Layout>
        </div>
    )
}
