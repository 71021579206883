import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media/index.js';
import { RootContext } from "../../web.js";
import Layout from "../../components/layout/main.js";

export default function Terms(props) {
    const [showHome, setShowHome] = useState(false);

    const root = useContext(RootContext);

    //On component mount
    useEffect(() => {
        setTimeout(()=>{
            setShowHome(true);
        }, 500);
    }, []);

    return (
        <div className="terms">
            <Layout pageId={1}>
                <div className='terms-container'>
                    <h1 className='heading'>{config.terms.heading}</h1>
                        <div className='terms-block'>
                            {config.terms.termsText.map((term, key1) => {
                                return(
                                    <div  key={key1} className='block' id={term.id ? term.id:""}>
                                        <h2 className="subheading" dangerouslySetInnerHTML={{__html:term.heading}}></h2>
                                        <div className='paragraphs'>
                                            {term.body.map((paragraph, key2) => {
                                                return(
                                                    <p key={key2} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </Layout>
        </div>
    )
}
