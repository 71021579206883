import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import media from '../../media';
import { RootContext } from "../../web.js";

export default function ArrowButton(props) {
    const root = useContext(RootContext);

    return props.type==="submit" ?
        <span className="arrow-button" onClick={props.onClick}>
            <input className="label" type="submit" value={props.label} />
            <span className="arrow">
                <span className="line"></span>
            </span>
        </span>
    :
        <a className="arrow-button" onClick={props.onClick}>
            <span className="label">{props.label}</span>
            <span className="arrow">
                <span className="line"></span>
            </span>
        </a>
}
