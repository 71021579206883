const media = {
    //Product logos

    coguide_white: require("./imgs/coguide_white.svg").default,
    coguide_black: require("./imgs/coguide_black.svg").default,

    codriver_white: require("./imgs/codriver_white.svg").default,
    codriver_black: require("./imgs/codriver_black.svg").default,

    coreview_white: require("./imgs/coreview_white.svg").default,
    coreview_black: require("./imgs/coreview_black.svg").default,

    cobooker_white: require("./imgs/cobooker_white.svg").default,
    cobooker_black: require("./imgs/cobooker_black.svg").default,

    coscore_white: require("./imgs/coscore_white.svg").default,
    coscore_black: require("./imgs/coscore_black.svg").default,


    //Brand logos




}

export default media;