import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import { RootContext } from "../../web.js";
import {replaceStrPatterns} from '../../utilities/general';

export default function Footer(props) {
    const [currYear, setCurrYear] = useState(new Date().getFullYear());

    const root = useContext(RootContext);

    return (
        <footer className="footer">
            <div className="inner">
                <div className="paragraphs">
                    {config.address.body.map((paragraph, key) => {
                        return(
                            <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                        );
                    })}
                </div>
                <span className="copyright">{replaceStrPatterns(config.copyright, [{pattern:"{{year}}", value:currYear}])}</span>
            </div>
        </footer>
    )
}
