import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import { RootContext } from "../../web.js";
import ContactForm from "../contact-form/main";

export default function SectionHeading(props) {
    const root = useContext(RootContext);

    return (
        <div className="contact-section">
            <div className="inner-content">
                <ContactForm />
            </div>
        </div>
    )
}
