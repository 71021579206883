import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import { RootContext } from "../../web.js";
import { useNavigate, useLocation } from "react-router-dom";
import {scrollToElementId} from '../../utilities/general';


export default function Topbar(props) {
    const [showPanel, setShowPanel] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const root = useContext(RootContext);

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);

        return () => {
            window.removeEventListener("resize", onWindowResize);
        }
    }, []);

    return (
        <div className="topbar">
            <div className="inner">
                <a className="logo" href="/"></a>

                <div className="menu">
                    <nav className="tabs">
                        {config.tabs.map((tab, key) => {
                            return(
                                <a className={"tab"+(props.pageId===key?" active":"")} key={key} onClick={()=>{onTabClick(key)}}>{tab.name}</a>
                                
                            );
                        })}
                    </nav>
                </div>
            </div>

            <div className={"overlay"+(showPanel?" open":"")}>
                <div className="panel">
                    <nav className="tabs">
                        {config.tabs.map((tab, key) => {
                            return(
                                <a className="tab" key={key} onClick={()=>{onTabClick(key)}}>
                                    <span className="text">{tab.name}</span>
                                </a>
                            );
                        })}
                    </nav>
                </div>
            </div>

            <a className="menu-btn" onClick={onMenuClick}>
                <span className={`line line1${showPanel ? " open" : ""}`}></span>
                <span className={`line line2${showPanel ? " open" : ""}`}></span>
                <span className={`line line3${showPanel ? " open" : ""}`}></span>
            </a>
        </div>
    )

    function onTabClick(tabId) {
        var scrollDelay = 0;

        setShowPanel(false); //Hide mobile panel if it is open

        //Navigate to corresponding page if necessary
        if (location.pathname!==config.tabs[tabId].pathname) {
            scrollDelay = 500;

            window.scroll({top:0});
            navigate(config.tabs[tabId].pathname);
        }

        //Scroll to corresponding element id if necessary
        if (config.tabs[tabId].hasOwnProperty("elementId")) {
            setTimeout(()=>{
                scrollToElementId(config.tabs[tabId].elementId);
            }, scrollDelay);
        }
    }

    function onMenuClick(e) {
        e.preventDefault();

        if (showPanel) {
            setShowPanel(false);
        }
        else {
            setShowPanel(true);
        }
    }

    function onWindowResize() {
        setShowPanel(false);
    }
}
